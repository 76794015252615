import { useState } from "react";
const isBrowser = typeof window !== "undefined";

const useLocalStorage = (key: string, initialValue: any, session = false) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (isBrowser === false) {
      return initialValue;
    }
    try {
      const item = window[session === true ? "sessionStorage" : "localStorage"].getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    setStoredValue(value);
    try {
      window[session === true ? "sessionStorage" : "localStorage"].setItem(
        key,
        JSON.stringify(value)
      );
    } catch (e) {
      // possible they've disabled in their browser
      console.error(e);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
