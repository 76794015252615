import React from "react";

type VariantPassthroughProps = {
  children: React.ReactNode;
};

/**
 * Render out children with no fallback.  Good if we want to
 * catch errors but not let the user know, or if the exception
 * won't crash the app.
 * @param {exampleProps} props.
 * @returns {React.ReactElement}
 */
const VariantPassthrough: React.FC<VariantPassthroughProps> = ({
  children
}): React.ReactElement => {
  const Logo = () => {
    return (
      <a href="/">
        <svg viewBox="0 0 395.5 90" height="50">
          <g fill="#010101">
            <g>
              <path d="M5.7,56.8v9.5H0V38.9h11.1c5.8,0,9.6,3.1,9.6,8.9c0,5.8-3.8,8.9-9.6,8.9H5.7z M10.8,43.8H5.7v8.1h5.1 c2.9,0,4.4-1.7,4.4-4.1C15.1,45.4,13.7,43.8,10.8,43.8z"></path>
              <path d="M45.3,38.9l4.7,18.4l4.5-18.4h5.6L53,66.3h-5.2l-4.8-18l-4.8,18H33l-7.1-27.3h5.7l4.4,18.4l4.8-18.4H45.3z"></path>
              <path d="M70.7,58.5c0.8,2.4,2.8,3.5,5.4,3.5c2.8,0,4.6-1.2,4.6-3.4c0-1.6-1-2.5-3.3-3.1l-4.9-1.1c-3.9-0.9-6.9-3-6.9-7.5 c0-4.9,4.1-8.4,9.8-8.4c6.2,0,9.8,3.3,10.7,7.9h-5.7c-0.6-1.8-2.2-3.2-5-3.2c-2.6,0-4.3,1.2-4.3,3.2c0,1.6,1,2.5,3.1,3l5,1.2 c4.4,1,7.2,3.6,7.2,7.7c0,5.3-4.4,8.5-10.1,8.5c-6.1,0-10.4-3-11.3-8.2H70.7z"></path>
            </g>
            <g>
              <path d="M217.8,38.9l6.4,23.4l6.3-23.4h2.2l-7.4,27.2h-2l-6.4-23.2l-6.4,23.2h-2L201,38.9h2.3l6.3,23.4l6.4-23.4H217.8z"></path>
              <path d="M256.7,38.9V41H242V51h13.3v2.1H242V64h14.7v2.1h-16.9V38.9H256.7z"></path>
              <path d="M275.7,38.9l10.8,27.2h-2.3l-3.5-9h-12.1l-3.5,9h-2.3l10.8-27.2H275.7z M269.4,55.1h10.5l-5.2-13.3L269.4,55.1z"></path>
              <path d="M289.4,38.9h22.2V41h-10v25.2h-2.3V41h-9.9V38.9z"></path>
              <path d="M340.9,38.9v27.2h-2.3v-13h-17.3v13h-2.3V38.9h2.3V51h17.3V38.9H340.9z"></path>
              <path d="M367.4,38.9V41h-14.7V51h13.3v2.1h-13.3V64h14.7v2.1h-16.9V38.9H367.4z"></path>
              <path d="M378.3,54.1v12H376V38.9h9.6c5.3,0,8.6,2.7,8.6,7.6c0,4.4-2.6,7-6.9,7.5l8.2,12.1h-2.6l-8.1-12H378.3z M378.3,52.1h7.3 c4.2,0,6.4-2.1,6.4-5.6c0-3.6-2.3-5.6-6.4-5.6h-7.3V52.1z"></path>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M178.8,22c-0.4,0.2-1,0.3-1.4,0.3h-70.8c-0.4,0-0.8-0.3-1-0.7l-8.4-21C97.1,0.3,97.3,0,97.7,0l12.1,0 c0.4,0,1,0.2,1.4,0.4l21.5,17.8c0.3,0.2,1,0.4,1.4,0.4h43.5c0.4,0,1,0.1,1.4,0.3l2.4,1.2c0.4,0.2,0.4,0.5,0,0.7L178.8,22z"></path>
                    <g>
                      <path d="M150.2,17l-0.4-0.9c-0.1-0.2-0.4-0.4-0.6-0.4h-11c-0.2,0-0.5,0.2-0.6,0.4l-0.4,0.9c0,0.1-0.1,0.3-0.1,0.4h13.2 C150.2,17.3,150.2,17.1,150.2,17z"></path>
                    </g>
                    <path d="M164.2,30.1c-5.3,0-9.6,3.8-10.5,8.8h-4.2l-0.5,3.8h4.7c0.9,5,5.2,8.8,10.5,8.8c5.9,0,10.7-4.8,10.7-10.7 S170.1,30.1,164.2,30.1z M169.8,46c0.6-1.2,1-2.6,1-4.1c0-4.6-3.3-8.3-7.4-8.7c0.3,0,0.5-0.1,0.8-0.1c4.3,0,7.7,3.5,7.7,7.7 C171.9,42.8,171.1,44.6,169.8,46z"></path>
                    <g>
                      <path d="M138.2,42.7l-0.5-3.8h-4.3c-0.8-5-4.8-8.8-9.5-8.8c-5.4,0-9.7,4.8-9.7,10.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1 c0.1,5.8,4.4,10.6,9.7,10.6h0c0,0,0,0,0,0c4.8,0,8.7-3.8,9.5-8.8H138.2z M123,48.5c-0.3,0-0.5-0.1-0.8-0.1 c4.2-0.4,7.4-4.1,7.4-8.7c0-1.5-0.4-2.9-1-4.1c1.3,1.4,2.1,3.2,2.1,5.2C130.7,45,127.2,48.5,123,48.5z"></path>
                      <path d="M113,40.8c0-4.6,2.4-8.5,5.9-10.5c-4.2,1.6-7.2,5.7-7.2,10.5c0,4.8,3,8.9,7.2,10.5C115.4,49.3,113,45.3,113,40.8z"></path>
                    </g>
                    <path d="M136.9,23.4l6,49.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7l6-49.1H136.9z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      fill="#06CCED"
                      d="M154.2,63.4c9.7,1.8,14.8,6.2,11.5,10.3c-3.6,4.5-16,7-27.6,5.6c-10.7-1.3-17-5.5-15.2-9.6c0,0,0,0,0,0 c-4.1,5.1,3.2,10.4,16.2,12c13,1.6,26.9-1.2,30.9-6.3C174.1,70.3,167,65,154.2,63.4z"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#0146E6"
                      d="M152,82.5c-14.3,1.4-28.9-1.9-32.5-7.5c-3.2-4.9,3-9.8,14.1-11.9c-13.7,2-21.7,7.7-18,13.3 c3.9,6,19.6,9.6,35.1,8.1c14.5-1.4,23.6-6.9,21.4-12.5C172.7,76.8,164.5,81.3,152,82.5z"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#3ABA50"
                      d="M182.3,71.8c0,8.3-17.3,15-38.7,15c-21.4,0-38.7-6.7-38.7-15c0-4.3,4.7-8.2,12.1-10.9 c-9.4,3-15.5,7.6-15.5,12.7c0,9.1,18.9,16.4,42.2,16.4s42.2-7.3,42.2-16.4c0-5.3-6.6-10.1-16.8-13.1 C177.1,63.3,182.3,67.3,182.3,71.8z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
            <g fillOpacity="0.4">
              <g>
                <path d="M261.9,86.5c0,1.7-1.2,2.6-3.1,2.6h-3.5v-9.2h3.2c1.9,0,3.1,0.9,3.1,2.5c0,1-0.5,1.7-1.2,1.9 C261.3,84.6,261.9,85.5,261.9,86.5z M258.5,83.8c1.1,0,1.6-0.4,1.6-1.3s-0.6-1.2-1.6-1.2h-1.7v2.5H258.5z M256.8,87.7h2 c1,0,1.6-0.5,1.6-1.3c0-0.8-0.6-1.3-1.6-1.3h-2V87.7z"></path>
                <path d="M270.6,79.9h1.6l-3.3,5.5v3.7h-1.5v-3.7l-3.3-5.5h1.7l2.4,4.1L270.6,79.9z"></path>
                <path d="M284.4,79.9l3.5,9.2h-1.6l-0.9-2.5h-3.5l-0.9,2.5h-1.6l3.5-9.2H284.4z M282.4,85.2h2.5l-1.3-3.4L282.4,85.2z "></path>
                <path d="M296.9,79.9v1.4h-4.5v2.4h4.1v1.4h-4.1v2.6h4.5v1.4h-6v-9.2H296.9z"></path>
                <path d="M301.8,85.4v3.7h-1.6v-9.2h3.6c1.9,0,3.1,1,3.1,2.8c0,1.5-0.9,2.4-2.4,2.7l2.7,3.8h-1.8l-2.6-3.7H301.8z M301.8,84.1h2c1,0,1.6-0.6,1.6-1.4s-0.6-1.4-1.6-1.4h-2V84.1z"></path>
                <path d="M311.8,89.1h-1.6v-9.2h1.6V89.1z"></path>
                <path d="M316.4,86.5c0.3,0.9,1.1,1.4,2.1,1.4c1.1,0,1.8-0.5,1.8-1.4c0-0.6-0.4-1-1.3-1.2l-1.6-0.4 c-1.3-0.3-2.3-1-2.3-2.5c0-1.6,1.3-2.7,3.1-2.7c2,0,3.2,1,3.5,2.5h-1.6c-0.2-0.7-0.8-1.2-1.9-1.2c-1,0-1.7,0.5-1.7,1.3 c0,0.6,0.4,1,1.2,1.2l1.6,0.4c1.4,0.3,2.4,1.2,2.4,2.5c0,1.7-1.4,2.8-3.3,2.8c-1.9,0-3.3-1-3.7-2.7H316.4z"></path>
                <path d="M330.4,79.9l1.7,6.8l1.7-6.8h1.5l-2.4,9.2h-1.4l-1.7-6.6l-1.8,6.6h-1.4l-2.4-9.2h1.5l1.7,6.8l1.8-6.8H330.4z "></path>
                <path d="M344.2,79.9v1.4h-4.5v2.4h4.1v1.4h-4.1v2.6h4.5v1.4h-6v-9.2H344.2z"></path>
                <path d="M351.9,79.9l3.5,9.2h-1.6l-0.9-2.5h-3.5l-0.9,2.5h-1.6l3.5-9.2H351.9z M349.9,85.2h2.5l-1.3-3.4L349.9,85.2z "></path>
                <path d="M356.9,79.9h7.7v1.4h-3.1v7.8H360v-7.8h-3.1V79.9z"></path>
                <path d="M375.5,79.9v9.2h-1.6v-4h-4.6v4h-1.6v-9.2h1.6v3.8h4.6v-3.8H375.5z"></path>
                <path d="M385.2,79.9v1.4h-4.5v2.4h4.1v1.4h-4.1v2.6h4.5v1.4h-6v-9.2H385.2z"></path>
                <path d="M390.1,85.4v3.7h-1.6v-9.2h3.6c1.9,0,3.1,1,3.1,2.8c0,1.5-0.9,2.4-2.4,2.7l2.7,3.8h-1.8l-2.6-3.7H390.1z M390.1,84.1h2c1,0,1.6-0.6,1.6-1.4s-0.6-1.4-1.6-1.4h-2V84.1z"></path>
              </g>
              <g>
                <path d="M98,79.8H0v9.3h109.9C104.4,86.7,100.2,83.6,98,79.8z"></path>
                <path d="M248.2,79.8H189c-2.2,3.8-6.4,6.9-11.9,9.3h71.1V79.8z"></path>
              </g>
            </g>
          </g>
        </svg>
      </a>
    );
  };
  return (
    <div
      style={{
        height: "100vh",
        padding: "5%"
      }}
    >
      <Logo />
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          marginTop: "48px"
        }}
      >
        <h1 style={{ fontSize: "24px" }}>We encountered an error displaying this page</h1>
        <p style={{ fontSize: "18px" }}>
          Our team has been notified. Email our support team at{" "}
          <a href="mailto:support@aerisweather.com?subject=PWSWeather Error Report" target="_blank">
            support@aerisweather.com
          </a>{" "}
          if you’d like to provide additional information regarding the error.
        </p>
        <button onClick={() => window.location.reload()} style={{ marginRight: "10px" }}>
          Reload page
        </button>
        <a href="/">Return to home page</a>
      </div>
    </div>
  );
};

export default VariantPassthrough;
