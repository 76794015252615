import { useState } from "react";
import { createContainer } from "unstated-next";
export let isMetricStore = false;
const isBrowser = typeof window !== "undefined";

function useIsMetric(initialState = false) {
  let localState;
  if (isBrowser === true) {
    localState = JSON.parse(window.localStorage.getItem("metric"));
  }
  const [isMetric, setValue] = useState(localState === null ? false : localState);
  function setIsMetric(value) {
    window.requestIdleCallback(() => setValue(value));
    window.localStorage.setItem("metric", JSON.stringify(value));
  }
  isMetricStore = isMetric;
  return [isMetric, setIsMetric];
}

const IsMetric = createContainer(useIsMetric);

export default IsMetric;
