"use strict";

exports.__esModule = true;
exports.CustomizeRule = void 0;
var CustomizeRule;
(function (CustomizeRule) {
  CustomizeRule["Match"] = "match";
  CustomizeRule["Merge"] = "merge";
  CustomizeRule["Append"] = "append";
  CustomizeRule["Prepend"] = "prepend";
  CustomizeRule["Replace"] = "replace";
})(CustomizeRule = exports.CustomizeRule || (exports.CustomizeRule = {}));