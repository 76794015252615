import _isNil from "lodash.isnil";
import {
  addDays,
  endOfMonth,
  endOfYear,
  format,
  isLeapYear,
  parseISO,
  startOfMonth,
  startOfYear
} from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";

import { utcToZonedTime } from "date-fns-tz";

export type ValueRange = { min: number; max: number };

export const roundTo = (x: number, multiple: number): number => {
  return Math.round(x / multiple) * multiple;
};

export const roundUpTo = (x: number, multiple: number): number => {
  return Math.ceil(x / multiple) * multiple;
};

export const roundDownTo = (x: number, multiple: number): number => {
  return Math.floor(x / multiple) * multiple;
};

export const toDateString = (date: Date): string => {
  return date.toLocaleDateString("us-en", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });
};

export const numberOfDecimals = (value: number): number => {
  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
};

export const roundToRange = (
  min: number,
  max: number,
  normalMin: number,
  normalMax: number,
  interval: number
): ValueRange => {
  const offset = interval / 2;
  let roundedMin = roundDownTo(min, interval);
  let roundedMax = roundUpTo(max, interval);

  if (_isNil(normalMin) === false && roundedMin > Math.round(normalMin)) {
    roundedMin = roundDownTo(normalMin, interval);
  }
  if (_isNil(roundedMax) === false && roundedMax < Math.round(normalMax)) {
    roundedMax = roundUpTo(normalMax, interval);
  }

  roundedMin -= offset;
  roundedMax += offset;

  return { min: roundedMin, max: roundedMax };
};

export const roundToDecimalRange = (
  min: number,
  max: number,
  normalMin: number,
  normalMax: number,
  interval: number
): ValueRange => {
  const decimals = numberOfDecimals(interval);
  const multiplier = Math.pow(10, decimals);

  min *= multiplier;
  max *= multiplier;

  if (_isNil(normalMin) === false) {
    normalMin *= multiplier;
  }
  if (_isNil(normalMax) === false) {
    normalMax *= multiplier;
  }

  const result = roundToRange(min, max, normalMin, normalMax, interval * multiplier);

  return {
    min: result.min / multiplier,
    max: result.max / multiplier
  };
};

export const clampRange = (range: ValueRange, min: number, max: number): ValueRange => {
  if (_isNil(min) === false) {
    range.min = Math.max(range.min, min);
  }
  if (_isNil(max) === false) {
    range.max = Math.min(range.max, max);
  }

  return range;
};

export const valueRangeString = (min: number, max: number, interval: number): string => {
  const roundedMin = roundTo(min, interval);
  const roundedMax = roundTo(max, interval);
  if (roundedMin === roundedMax) {
    return `${roundedMin}`;
  }
  return `${roundedMin}-${roundedMax}`;
};

export const isZipcode = (str: string): boolean =>
  /^(\d\d\d\d\d(?:-\d\d\d\d)?|[ABCEGHJKLMNPRSTVXY]\d[A-Z] ?\d[A-Z]\d)$/.test(str);

export const isCoord = (str: string): boolean => /^(-?[\d\.]+),\s*(-?[\d\.]+)$/.test(str);

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const $ = (selector: string, context = document) =>
  Array.from(context.querySelectorAll(selector));

export const numberOfDaysInAMonth = (month: number, year: number) => {
  if (month === 2 && isLeapYear(year) === true) {
    return 29;
  }

  if (month === 2 && isLeapYear(year) === false) {
    return 28;
  }
  const daysInMonth: { [key: number]: number } = {
    1: 31,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  };
  return daysInMonth[month];
};

export const formDataToJson = (formData: FormData, asJsonString = true): string | {} => {
  const object = {} as any;
  formData.forEach((value, key) => {
    object[key] = value;
  });
  if (asJsonString) {
    const json = JSON.stringify(object);
    return json;
  }
  return object;
};

export const getDateString = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  const formatted = format(parsedTime, "yyyy-MM-dd");
  return formatted;
};

export const getDate = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  return parsedTime;
};

export const getTomorrowDateString = (dateString, timezone) => {
  const time = `${dateString}T00:00:00`;
  const parsedTime = parseISO(time);
  const newDate = addDays(parsedTime, 1);
  const formatted = format(newDate, "yyyy-MM-dd");
  return formatted;
};

export const startOfMonthDateString = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  const newDate = startOfMonth(parsedTime);
  const formatted = format(newDate, "yyyy-MM-dd");
  return formatted;
};

export const endOfMonthDateString = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  const newDate = endOfMonth(parsedTime);
  const formatted = format(newDate, "yyyy-MM-dd");
  return formatted;
};

export const startOfYearDateString = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  const newDate = endOfMonth(parsedTime);
  const formatted = format(newDate, "yyyy-MM-dd");
  return formatted;
};

export const endOfYearDateString = (string, timezone) => {
  const time = `${string}T00:00:00`;
  const parsedTime = parseISO(time);
  const newDate = endOfYear(parsedTime);
  const formatted = format(newDate, "yyyy-MM-dd");
  return formatted;
};
