import { css } from "@emotion/react";
import { Link as ReachLink } from "gatsby";
import { Card, Link, Theme } from "pws-design-system/design-system";
import React from "react";

export interface DisplayVariantCardProps {}

const DisplayVariantCard: React.FC<DisplayVariantCardProps> = (props): React.ReactElement => {
  return (
    <Card
      boxShadow="sm"
      mb={3}
      p={3}
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...props}
    >
      <div>
        Data could not be displayed at this time and our team has been notified.
        <br /> <br />
        <Link
          as={ReachLink}
          to="/support"
          css={css`
            color: ${Theme.colors.brand.green.base};
            &:hover {
              color: ${Theme.colors.brand.green.base};
            }
            :after {
              border-bottom-color: ${Theme.colors.brand.green.base};
            }
          `}
        >
          Contact our support team if you’d like to provide additional information regarding the
          error.
        </Link>
      </div>
    </Card>
  );
};

export default DisplayVariantCard;
