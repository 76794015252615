import merge from "webpack-merge";
import { Theme, DarkTheme as _DarkTheme } from "pws-design-system/design-system/";
import _clonedeep from "lodash.clonedeep";

const ExtendedLightTheme = merge(Theme, {
  name: "light",
  colors: {
    base: {
      dark: Theme.colors.bg.dark,
      light: Theme.colors.bg.light
    },
    bg: {
      base: {
        primary: Theme.colors.bg.base.primary,
        secondary: Theme.colors.bg.base.secondary,
        tertiary: Theme.colors.bg.base.tertiary
      },
      inverse: {
        primary: Theme.colors.bg.reversed.primary,
        secondary: Theme.colors.bg.reversed.secondary,
        tertiary: Theme.colors.bg.reversed.tertiary
      }
    },
    text: {
      base: {
        primary: Theme.colors.text.base.primary,
        secondary: Theme.colors.text.base.secondary,
        tertiary: Theme.colors.text.base.tertiary
      },
      inverse: {
        primary: Theme.colors.text.reversed.primary,
        secondary: Theme.colors.text.reversed.secondary,
        tertiary: Theme.colors.text.reversed.tertiary
      }
    }
  }
});

const ExtendedDarkTheme = merge(Theme, {
  name: "dark",
  colors: {
    base: {
      dark: Theme.colors.bg.dark,
      light: Theme.colors.bg.light
    },
    bg: {
      base: {
        primary: _DarkTheme.colors.bg.base.primary,
        secondary: _DarkTheme.colors.bg.base.secondary,
        tertiary: _DarkTheme.colors.bg.base.tertiary
      },
      inverse: {
        primary: _DarkTheme.colors.bg.reversed.primary,
        secondary: _DarkTheme.colors.bg.reversed.secondary,
        tertiary: _DarkTheme.colors.bg.reversed.tertiary
      }
    },
    text: {
      base: {
        primary: _DarkTheme.colors.text.base.primary,
        secondary: _DarkTheme.colors.text.base.secondary,
        tertiary: _DarkTheme.colors.text.base.tertiary
      },
      inverse: {
        primary: _DarkTheme.colors.text.reversed.primary,
        secondary: _DarkTheme.colors.text.reversed.secondary,
        tertiary: _DarkTheme.colors.text.reversed.tertiary
      }
    }
  }
});

const customLight = {
  text: {
    primary: ExtendedDarkTheme.colors.text.dark.primary,
    secondary: ExtendedDarkTheme.colors.text.dark.secondary,
    tertiary: ExtendedDarkTheme.colors.text.dark.tertiary
  },
  components: {
    page: {
      logo: {
        variant: "dark",
        mobile: {
          variant: "dark"
        }
      }
    },
    mainNavigation: {
      color: ExtendedDarkTheme.colors.text.base.primary
    },
    features: {
      bg: ExtendedLightTheme.colors.bg.base.primary,
      color: ExtendedLightTheme.colors.text.base.primary
    },
    autosuggest: {
      input: {
        bg: ExtendedLightTheme.colors.bg.base.secondary,
        bgFocused: "#fff",
        color: ExtendedLightTheme.colors.text.base.primary,
        icon: {
          bg: "brand.gray.800"
        },
        placeholder: {
          color: "brand.gray.800"
        }
      },
      listing: {
        bg: ExtendedLightTheme.colors.bg.base.primary,
        color: ExtendedLightTheme.colors.text.base.primary
      },
      result: {
        borderColor: "brand.gray.200",
        hover: {
          bg: "brand.gray.100"
        }
      }
    },
    map: {
      panel: {
        bg: ExtendedLightTheme.colors.bg.base.primary,
        color: ExtendedLightTheme.colors.text.base.primary,
        date: {
          color: "brand.gray.700"
        },
        windMeter: {
          arrowIcon: {
            fill: ExtendedLightTheme.colors.base.dark
          }
        }
      }
    },
    card: {
      bg: ExtendedLightTheme.colors.bg.base.primary,
      color: ExtendedLightTheme.colors.text.base.primary,
      nearbyStations: {
        tableCell: {
          borderColor: "brand.gray.200"
        },
        tableRow: {
          hover: {
            bg: ExtendedLightTheme.colors.bg.base.primary
          }
        },
        pagination: {
          color: "dark",
          variant: "ghost"
        }
      },
      historical: {
        unitToggle: {
          selectedVariantColor: "dark"
        }
      },
      forecast: {
        viewToggle: {},
        period: {
          hover: "brand.gray.100"
        }
      },
      map: {
        unitToggle: {
          variant: "link",
          selectedVariant: "outline-fill",
          selectedVariantColor: "dark",
          variantColor: "light"
        }
      }
    },
    forecast: {
      period: {
        hover: "#ebedef"
      },
      viewToggle: {
        bg: "#ebedef",
        variant: "link",
        selectedVariant: "outline-fill",
        variantColor: "dark",
        selectedVariantColor: "dark"
      }
    },
    mapPage: {
      logo: {
        variant: "dark",
        mobile: {
          variant: "dark"
        }
      },
      mainNavigation: {
        color: ExtendedLightTheme.colors.text.base.primary
      },
      toggle: {
        bg: "transparent",
        p: "0",
        variantColor: "light"
      },
      popover: {
        bg: ExtendedLightTheme.colors.bg.base.primary,
        color: ExtendedLightTheme.colors.text.base.primary,
        secondaryColor: ExtendedLightTheme.colors.text.base.primary,
        title: {
          borderColor: "brand.gray.800"
        }
      }
    },
    home: {
      mainNavigation: {
        color: ExtendedLightTheme.colors.text.inverse.primary
      },
      unitToggle: {
        bg: ExtendedLightTheme.colors.bg.inverse.primary,
        variantColor: "light",
        selectedVariantColor: "solid",
        popover: {
          bg: ExtendedLightTheme.colors.bg.base.primary,
          color: ExtendedLightTheme.colors.text.base.primary,
          secondaryColor: ExtendedLightTheme.colors.text.base.primary,
          title: {
            borderColor: "brand.gray.800"
          }
        }
      }
    },
    dashboard: {
      mainNavigation: {
        color: ExtendedLightTheme.colors.text.base.primary
      },
      logo: { variant: "dark" },
      header: {
        bg: "brand.gray.100",
        title: { color: ExtendedLightTheme.colors.text.base.secondary },
        subtitle: {
          color: ExtendedLightTheme.colors.text.base.secondary
        },
        horizontalRule: {
          color: "brand.gray.200"
        },
        stationTypeLabel: {
          color: ExtendedLightTheme.colors.text.base.primary
        },
        navItem: {
          variantColor: "dark"
        },
        unitToggle: {
          bg: Theme.colors.brand.gray["100"],
          variantColor: "dark",
          selectedVariantColor: "dark",
          popover: {
            bg: ExtendedLightTheme.colors.bg.base.primary,
            color: ExtendedLightTheme.colors.text.base.primary,
            secondaryColor: ExtendedLightTheme.colors.text.base.secondary,
            title: {
              borderColor: "brand.gray.200"
            }
          }
        }
      },
      content: { bg: "brand.gray.100" },
      card: {
        bg: "#fff",
        nearbyStations: {
          tableCell: {
            borderColor: "brand.gray.200"
          },
          tableRow: {
            hover: {
              bg: ExtendedLightTheme.colors.bg.base.primary
            }
          },
          pagination: {
            color: "dark",
            variant: "ghost"
          }
        }
      }
    },
    charts: {
      border: {
        bg: "brand.gray.500"
      },
      labels: {
        bg: "brand.gray.100"
      },
      data: {
        temperature: "#b50000",
        dewpoint: "#1a16d5",
        direction: "#c60087",
        feelslike: "#eab800",
        windspeed: "#1a16d5",
        windgust: "#30bbe1",
        pressure: "#d45300",
        precip: "#30bbe1",
        precipaccum: "#0f561e",
        snow: "#c60087",
        snowaccum: "#342b79",
        humidity: "#5ecb32",
        skycover: "#494e4e",
        pop: "#3162e7",
        uv: "#c60087",
        solrad: "#EAB800"
      }
    },
    table: {
      borderBottomColor: "brand.gray.100",
      arrowIcon: {
        fill: "black"
      }
    },
    unitToggle: {
      bg: ExtendedLightTheme.colors.bg.base.primary
    },
    historical: {
      toggle: {
        bg: "#eee",
        variant: "link",
        variantColor: "dark",
        selectedVariantColor: "dark",
        selectedVariant: "outline-fill"
      }
    },
    tabs: {
      border: "#333",
      color: "#333",
      active: {
        color: ExtendedLightTheme.colors.text.primary
      }
    },
    meter: {
      bg: "bg.100"
    }
  }
};

const customDark = {
  text: {
    primary: ExtendedDarkTheme.colors.text.light.primary,
    secondary: ExtendedDarkTheme.colors.text.light.secondary,
    tertiary: ExtendedDarkTheme.colors.text.light.tertiary
  },
  components: {
    page: {
      logo: {
        variant: "light",
        mobile: {
          variant: "light"
        }
      },
      header: {
        variant: "light"
      }
    },
    mainNavigation: {
      color: ExtendedDarkTheme.colors.text.base.primary
    },
    features: {
      bg: ExtendedDarkTheme.colors.bg.base.primary,
      color: ExtendedDarkTheme.colors.text.base.primary
    },
    autosuggest: {
      input: {
        bg: "brand.gray.1300",
        bgFocused: "brand.gray.1100",
        color: ExtendedDarkTheme.colors.text.base.primary,
        icon: {
          bg: "brand.gray.200"
        },
        placeholder: {
          color: "brand.gray.200"
        }
      },
      listing: {
        bg: "brand.gray.1100",
        color: ExtendedDarkTheme.colors.text.base.primary
      },
      result: {
        borderColor: "brand.gray.800",
        hover: {
          bg: "#5e656e"
        }
      }
    },
    map: {
      panel: {
        bg: "brand.gray.1200",
        color: ExtendedDarkTheme.colors.text.base.primary,
        date: {
          color: "brand.gray.300"
        },
        windMeter: {
          bg: "brand.gray.base",
          arrowIcon: {
            fill: ExtendedDarkTheme.colors.base.light
          }
        }
      }
    },
    card: {
      bg: ExtendedDarkTheme.colors.bg.base.secondary,
      color: ExtendedDarkTheme.colors.text.base.primary,
      nearbyStations: {
        tableCell: {
          borderColor: "brand.gray.base"
        },
        tableRow: {
          hover: {
            bg: ExtendedDarkTheme.colors.bg.base.primary
          }
        },
        pagination: {
          color: "light-darkTheme",
          variant: "ghost"
        }
      },
      historical: {
        unitToggle: {
          bg: "black",
          variant: "link",
          selectedVariant: "outline-fill",
          variantColor: "light",
          selectedVariantColor: "light"
        }
      },
      map: {
        unitToggle: {
          bg: "black",
          variant: "link",
          selectedVariant: "outline-fill",
          variantColor: "light",
          selectedVariantColor: "light"
        }
      }
    },
    forecast: {
      period: {
        hover: "#5e656e"
      },
      viewToggle: {
        bg: "transparent",
        variant: "link",
        selectedVariant: "outline-fill",
        variantColor: "light",
        selectedVariantColor: "light"
      }
    },
    mapPage: {
      logo: {
        variant: "light",
        mobile: {
          variant: "light"
        }
      },
      mainNavigation: {
        color: "light"
      },
      toggle: {
        bg: "transparent",
        p: "0",
        variantColor: "light",
        selectedVariantColor: "light"
      },
      popover: {
        bg: ExtendedDarkTheme.colors.bg.base.secondary,
        color: ExtendedDarkTheme.colors.text.base.primary,
        secondaryColor: ExtendedDarkTheme.colors.text.base.primary,
        title: {
          borderColor: "brand.gray.800"
        }
      }
    },
    home: {
      mainNavigation: {
        color: ExtendedDarkTheme.colors.text.base.primary
      },
      unitToggle: {
        bg: ExtendedDarkTheme.colors.bg.base.primary,
        variantColor: "light",
        selectedVariantColor: "light",
        popover: {
          bg: ExtendedDarkTheme.colors.bg.base.secondary,
          color: ExtendedDarkTheme.colors.text.base.primary,
          secondaryColor: ExtendedDarkTheme.colors.text.base.primary,
          title: {
            borderColor: "brand.gray.800"
          }
        }
      }
    },
    dashboard: {
      mainNavigation: {
        color: ExtendedDarkTheme.colors.text.base.primary
      },
      logo: {
        variant: "light",
        mobile: {
          variant: "light"
        }
      },
      header: {
        bg: ExtendedDarkTheme.colors.bg.base.primary,
        title: { color: ExtendedDarkTheme.colors.text.base.primary },
        subtitle: {
          color: ExtendedDarkTheme.colors.text.base.primary
        },
        horizontalRule: {
          color: "brand.gray.800"
        },
        stationTypeLabel: {
          color: ExtendedDarkTheme.colors.text.base.primary
        },
        navItem: {
          variantColor: "light-darkTheme",
          selectedVariantColor: "brand"
        },
        unitToggle: {
          bg: ExtendedDarkTheme.colors.bg.base.primary,
          variantColor: "light",
          selectedVariantColor: "light",
          popover: {
            bg: ExtendedDarkTheme.colors.bg.base.tertiary,
            color: ExtendedDarkTheme.colors.text.base.primary,
            secondaryColor: ExtendedDarkTheme.colors.text.base.primary,
            variantColor: "light",
            selectedVariantColor: "light",
            title: {
              borderColor: "brand.gray.800"
            }
          }
        }
      },
      content: { bg: ExtendedDarkTheme.colors.bg.base.primary },
      card: {
        bg: "#212326"
      }
    },
    charts: {
      border: {
        bg: "brand.gray.base"
      },
      labels: {
        bg: "brand.gray.1200"
      },
      data: {
        temperature: "#b50000",
        dewpoint: "#1b52ff",
        feelslike: "#b68f00",
        windspeed: "#1b52ff",
        windgust: "#30bbe1",
        pressure: "#899393",
        precip: "#30bbe1",
        precipaccum: "#0a9e28",
        snow: "#c60087",
        snowaccum: "#6143ff",
        humidity: "#0a9e28",
        skycover: "#899393",
        pop: "#1b52ff",
        uv: "#ab0075",
        solrad: "#b68f00"
      }
    },
    table: {
      borderBottomColor: "#5e656e",
      arrowIcon: {
        fill: ExtendedDarkTheme.colors.text.base.primary
      }
    },
    unitToggle: {
      bg: ExtendedDarkTheme.colors.bg.base.primary
    },
    historical: {
      toggle: {
        bg: "#222",
        variant: "link",
        variantColor: "light",
        selectedVariantColor: "light",
        selectedVariant: "outline-fill"
      }
    },
    tabs: {
      border: "#ccc",
      color: "#ccc",
      active: {
        color: ExtendedDarkTheme.colors.text.primary
      }
    },
    meter: {
      bg: "brand.gray.base",
      disabledBgColor: "brand.gray.1100"
    }
  }
};

const LightTheme = merge(ExtendedLightTheme, customLight);
const DarkTheme = merge(ExtendedDarkTheme, customDark);

export { LightTheme, DarkTheme };
