import React, { ErrorInfo } from "react";
import Sentry from "../../../../error";
import VariantCard from "./VariantCard";
import VariantFullPage from "./VariantFullPage";

interface ErrorBoundaryProps {
  variant: "card" | "default" | "fullPage";
  minHeight?: string;
  children: React.ReactNode;
}

const Noop = () => <></>;

const variantMap = {
  default: Noop,
  fullPage: VariantFullPage,
  card: VariantCard
};

const reportErrors = process.env.GATSBY_ERROR_REPORT_ENABLE;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: Boolean }> {
  static defaultProps = {
    variant: "default"
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, ...props };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (reportErrors !== "true") {
      return;
    }
    console.error(error);
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ hasError: true });
    });
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { variant, ...displayProps } = this.props;

    if (this.state.hasError === true) {
      const Display = variantMap[variant];
      return <Display {...displayProps} />;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
