import { useState } from "react";
import { createContainer } from "unstated-next";
import * as yup from "yup";
import { PreferenceKeys, ClockType, HourFormatTokens, LocaleTokens } from "../../../../types/enums";
import PersistentStore from "./PersistentStore";

class TimeStore extends PersistentStore {
  key = PreferenceKeys.ClockType;
  defaults = {
    format: ClockType.TwelveHour
  };

  schema = yup
    .object()
    .required()
    .shape({
      format: yup.string().oneOf(Object.values(ClockType))
    });

  public get format() {
    return this.getValue("format");
  }
}

const timeStore = new TimeStore();

const useTimeStore = () => {
  const [timeFormat, setTimeFormatState] = useState(timeStore.format);

  const setTimeFormat = (val: ClockType) => {
    timeStore.setValue("format", val);
    setTimeFormatState(val);
  };

  const getHourFormatToken = () => {
    if (timeFormat === ClockType.TwelveHour) {
      return HourFormatTokens.TwelveHour;
    }
    if (timeFormat === ClockType.TwentyFourHour) {
      return HourFormatTokens.TwentyFourHour;
    }
  };

  const getLocaleToken = () => {
    if (timeFormat === ClockType.TwelveHour) {
      return LocaleTokens.TwelveHour;
    }
    if (timeFormat === ClockType.TwentyFourHour) {
      return LocaleTokens.TwentyFourHour;
    }
  };

  const getTimeFormatString = () => {
    return `${getHourFormatToken()}:mm${timeFormat === ClockType.TwelveHour ? " a" : ""}`;
  };

  return {
    timeFormat,
    setTimeFormat,
    getHourFormatToken,
    getLocaleToken,
    getTimeFormatString,
    setTimeFormatState
  };
};

const TimeStoreContainer = createContainer(useTimeStore);
export { timeStore, TimeStoreContainer };
