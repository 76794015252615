export enum Z {
  hamburgerMenu = 10000,
  hamburgerOverlayPanel = 102,
  announcement = 5,
  mobileDatepicker = 4,
  dropdown = 3,
  autosuggest = 1,
  pageHeader = 1,
  map = 0
}

export enum BREAKPOINT {
  XS = 512,
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1200,
  XXL = 1376
}

export enum CardPeriod {
  Daily = "daily",
  Monthly = "monthly",
  Yearly = "yearly"
}

export enum CardView {
  Tabular = "tabular",
  Graph = "graphs"
}

export enum DeviceFormFactor {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop"
}

export enum CountriesThatRequireStates {
  UnitedStates = "us",
  China = "cn",
  Canada = "ca"
}

export enum Views {
  Forecast = "forecast",
  Map = "map",
  Dashboard = "dashboard",
  Historical = "historical"
}

export enum PlaceType {
  Station = "station",
  Location = "local"
}

export enum StationType {
  Metar = "metar",
  Aus = "aus",
  Pws = "pws",
  Madis = "madis"
}

export enum StationTypePrefixes {
  Metar = "mid",
  Pws = "pws",
  Aus = "aus"
}

export enum LocalStorageKeys {
  HistoricalCardSummaryView = "pws-historical-card-observation-history-view",
  SummaryCardView = "pws-historical-card-daily-summary-view",
  MobileForecastCardView = "pws-mobile-forecast-card-view",
  PrismicPreviewData = "pws-preview-data",
  AnnouncementVisible = "pws-announcement-visible"
}

export enum ChartLabels {
  Precip = "Precip",
  AccumulatedPrecip = "Acccumulated Precip"
}

export enum ChartColors {
  Precip = "#0a9626",
  AccumulatedPrecip = "#0f561e"
}

/**
 * Measurement systems
 * @export
 * @enum {string}
 */
export enum UnitTypes {
  Imperial = "imperial",
  Metric = "metric",
  Custom = "custom"
}

/**
 * Used to group api data points into buckets
 * for formatting purposes.
 * @export
 * @enum {string}
 */
export enum UnitCategory {
  Temp = "temp",
  Speed = "speed",
  Distance = "distance",
  Direction = "direction",
  Height = "height",
  Pressure = "pressure",
  Rain = "rain",
  Snow = "snow",
  Text = "text",
  Percent = "percent"
}

export enum ApiPropGroups {
  Temp = "temp",
  Dewpoint = "dewpoint",
  Pressure = "pressure",
  Wind = "wind",
  Precip = "precip",
  Dewpoint = "dewpoint"
}

/**
 * @export
 * @enum {string}
 */
export enum ApiProps {
  TempMetric = "tempC",
  TempImperial = "tempF",
  PressureMetric = "pressureMb",
  PressureImperial = "pressureIn",
  WindSpeedMetric = "windKph",
  WindSpeedImperial = "windMph",
  PrecipMetric = "precipMm",
  PrecipImperial = "precipIn",
  FeelsLikeMetric = "feelsLikeC",
  FeelsLikeImperial = "feelsLikeF",
  DewpointMetric = "dewpointC",
  DewpointImperial = "dewpointF"
}

/**
 * @enum {string}
 */
export enum Units {
  Celsius = "°C",
  Fahrenheit = "°F",
  KilometersPerhour = "km/h",
  MilesPerHour = "mph",
  Knots = "kts",
  MetersPerSecond = "m/s",
  Kilometers = "km",
  Miles = "mi",
  Meters = "m",
  Feet = "ft",
  Inches = "in",
  Millibars = "mb",
  Percent = "%",
  Millimeters = "mm",
  Centimeters = "cm"
}

/**
 * Used to index stored user preferences
 * @export
 * @enum {string}
 */
export enum PreferenceKeys {
  Preferences = "preferences",
  Units = "unitHash",
  ClockType = "time",
  Pressure = "pressure",
  AuthToken = "authToken"
}

export enum ClockType {
  TwelveHour = "12",
  TwentyFourHour = "24"
}

export enum PressureType {
  Altimeter = "altimeter",
  Station = "station",
  MeanSeaLevelPressure = "mslp"
}

export enum HourFormatTokens {
  TwelveHour = "h",
  TwentyFourHour = "H"
}

export enum LocaleTokens {
  TwelveHour = "en-US",
  TwentyFourHour = "en-GB"
}

/**
 * Refresh time in minutes for various components
 * @export
 * @enum {number}
 */
export enum RefreshRates {
  LatestObservationCard = 5,
  StationMapCard = 5,
  NearbyStationsCard = 5,
  ForecastCard = 30,
  SummaryCard = 15,
  HistoryCard = 15
}

export enum ErrorCodes {}
