import useLocalStorage from "../useLocalStorage";
import _has from "lodash.has";
import { LocalStorageKeys } from "../../../../types/enums";
import { createContainer } from "unstated-next";
import { isBrowser } from "../../../../utils";

const usePreviewData = (initialState = false) => {
  const [previewData, setPreviewDataState] = useLocalStorage(
    LocalStorageKeys.PrismicPreviewData,
    null,
    false
  );

  const setPreviewData = (data: any) => {
    setPreviewDataState(data);
  };

  // if (isBrowser()) {
  //   localStorage.removeItem(LocalStorageKeys.PrismicPreviewData);
  // }

  return [previewData, setPreviewData];
};

let UsePreviewDataContainer;

UsePreviewDataContainer = createContainer(usePreviewData);

export default UsePreviewDataContainer;
