import React from "react";
import { CSSReset, GlobalStyle, ThemeProvider } from "pws-design-system/design-system/";
import { PressureTypeContainer } from "./src/react/common//hooks/stores/usePressureTypeStore";
import { TimeStoreContainer } from "./src/react/common//hooks/stores/useTimeStore";
import { UnitStoreContainer } from "./src/react/common//hooks/stores/useUnitStore";
import IsMetric from "./src/react/common/hooks/useIsMetric";
import ErrorBoundary from "./src/react/common/components/error-boundary/";
import UsePreviewDataStoreContainer from "./src/react/common/hooks/stores/usePreviewDataStore";
import { ThemeContainer } from "./src/react/common/hooks/useTheme";

export function onClientEntry() {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!("IntersectionObserver" in window)) {
    require("intersection-observer");
  }

  if (!("requestIdleCallback" in window)) {
    require("requestidlecallback");
  }
}

function RenderElement({ children }) {
  const { theme } = ThemeContainer.useContainer();
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}

// export function wrapPageElement({ element, props }) {
//   return <RenderElement>{element}</RenderElement>;
// }

// export function wrapRootElement({ element, props }) {
//   return (
//     <PressureTypeContainer.Provider>
//       <TimeStoreContainer.Provider>
//         <UnitStoreContainer.Provider>
//           <IsMetric.Provider>
//             <ThemeContainer.Provider>
//               <UsePreviewDataStoreContainer.Provider>
//                 <RenderElement>{element}</RenderElement>
//               </UsePreviewDataStoreContainer.Provider>
//             </ThemeContainer.Provider>
//           </IsMetric.Provider>
//         </UnitStoreContainer.Provider>
//       </TimeStoreContainer.Provider>
//     </PressureTypeContainer.Provider>
//   );
// }

let timeoutHandle;
export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (process.env.NODE_ENV !== `production`) {
    return null;
  }
  clearTimeout(timeoutHandle);

  function sendEvent() {
    if (!window.ga || typeof window.ga !== "function") {
      clearTimeout(timeoutHandle);
      timeoutHandle = setTimeout(sendEvent, 500);
      return;
    }
    clearTimeout(timeoutHandle);
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    window.ga("set", "page", pagePath);
    window.ga("send", "pageview");
  }

  sendEvent();

  return null;
};
