import { useState } from "react";
import { createContainer } from "unstated-next";
import { DarkTheme, LightTheme } from "./Theme";

const isBrowser = typeof window !== "undefined";

export const themes: { [index: string]: { value: any } } = {
  light: LightTheme,
  dark: DarkTheme
};

function useTheme() {
  let themeName = "light";

  if (isBrowser === true) {
    const storedThemeName = window.localStorage.getItem("theme");
    if (storedThemeName) {
      themeName = storedThemeName;
    } else {
      window.localStorage.setItem("theme", themeName);
    }
  }

  const [theme, setThemeState] = useState(themes[themeName]);

  function setTheme(value: any) {
    setThemeState(themes[value]);
    window.localStorage.setItem("theme", value);
  }

  return { theme, setTheme };
}

const ThemeContainer = createContainer(useTheme);
export { ThemeContainer };
