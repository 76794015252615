import { useState } from "react";
import { createContainer } from "unstated-next";
import * as yup from "yup";
import { PreferenceKeys, PressureType } from "../../../../types/enums";
import PersistentStore from "./PersistentStore";

class PressureTypeStore extends PersistentStore {
  key = PreferenceKeys.Pressure;

  defaults = {
    type: "altimeter"
  };

  schema = yup
    .object()
    .required()
    .shape({
      type: yup.string().oneOf(["altimeter", "mslp", "station"])
    });

  public get type() {
    return this.getValue("type");
  }
}

const pressureStore = new PressureTypeStore();

const usePressureStore = () => {
  const [pressureType, setPressureTypeState] = useState(pressureStore.type);

  const setPressureType = (val: PressureType) => {
    pressureStore.setValue("type", val);
    setPressureTypeState(val);
  };

  return {
    pressureType,
    setPressureType
  };
};

const PressureTypeContainer = createContainer(usePressureStore);
export { pressureStore, PressureTypeContainer };
