import _isNil from "lodash.isnil";
import { unitStore } from "../../react/common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../types/enums";

export const UnitKey = {
  metric: 0,
  imperial: 1,
  custom: 2
};

export const units: { [key: string]: string[] } = {
  temp: ["°C", "°F"],
  speed: ["km/h", "mph", "kts", "m/s"],
  distance: ["km", "mi"],
  direction: ["deg", "deg"],
  height: ["m", "ft"],
  pressure: ["mb", "in"],
  rain: ["mm", "in"],
  snow: ["cm", "in"],
  percent: ["%", "%"]
};

const patterns: { [key: string]: string } = {
  temp: "(temp|dewpoint|dewpt|feelslike|windchill|heatindex)",
  speed: "(speed|gust|wind)",
  direction: "(direction)",
  distance: "(visibility)",
  height: "(ceiling|elev|elevation)",
  pressure: "(pressure|altimeter)",
  percent: "(rh|humid|pop|prob|skycover)",
  rain: "(precip|rain|prcp)",
  snow: "(snow|snowdepth)",
  text: "(phrase)"
};

export const unitForDataType = (type: string, isMetric: boolean = false): string => {
  if (units[type]) {
    if (type === "speed") {
      if (unitStore[UnitCategory.Speed] === "custom") {
        //return units[type][UnitKey.custom];
        return unitStore.values["speed"];
      }
    }
    const index = isMetric ? UnitKey.metric : UnitKey.imperial;
    return units[type][index];
  }
  return "";
};

export const unitForProp = (prop: string, isMetric: boolean = false): string => {
  const type = dataTypeForProp(prop, isMetric);
  let result = "";

  if (type) {
    result = unitForDataType(type, isMetric);
  }

  return result;
};

export const dataTypeForProp = (prop: string): string => {
  let result: string;
  prop = (prop || "").toLowerCase().replace(/\s/g, "");

  if (undefined !== patterns[prop]) {
    return prop;
  }

  if (/prob(ability)?/.test(prop)) {
    return "percent";
  }

  Object.keys(patterns).forEach(key => {
    const re = new RegExp(patterns[key], "i");
    if (re.test(prop)) {
      result = key;
    }
  });

  return result;
};

export const formatValue = (
  value: any,
  prop: string,
  isMetric: boolean = false,
  includeUnits: boolean = false,
  includeSpace: boolean = false
): string => {
  const type = dataTypeForProp(prop);
  if (_isNil(value)) {
    return "--";
  }
  if (!type) {
    return `${value}`;
  }

  let decimals = 0;
  let units: string = "";

  if (type === "pressure") {
    decimals = isMetric ? 1 : 2;
  } else if (type === "rain") {
    decimals = isMetric ? 1 : 2;
  } else if (type === "snow" || type === "temp" || type === "speed") {
    decimals = 1;
  }

  if (decimals > 0) {
    value = parseFloat(value).toFixed(decimals);
  } else {
    value = Math.round(value);
  }

  if (includeUnits) {
    units = unitForDataType(type, isMetric);
  }

  return `${value}${includeSpace ? " " : ""}${units}`;
};

export const appendUnits = (value: number | string, type: string, isMetric: boolean): string => {
  return `${value} ${unitForDataType(type, isMetric)}`;
};

export const metersToMiles = (value: number): number => {
  return value * 0.000621371;
};

export const metersToKilometers = (value: number): number => {
  return value / 1000;
};

export const kmhToMs = (kmh: any): any => {
  const metersPerKm = 1000;
  const secondsPerHour = 3600;
  return (kmh * metersPerKm) / secondsPerHour;
};

export default units;
