import _get from "lodash.get";
import _set from "lodash.set";
import * as yup from "yup";
import { PreferenceKeys } from "../../../../types/enums";
import { isBrowser } from "../../../../utils";

export default abstract class PersistentStore {
  protected key: PreferenceKeys;
  protected schema: yup.ObjectSchema;
  protected defaults: any;
  protected _values: any;

  private getLocalStorage() {
    const json = window.localStorage.getItem(this.key);
    const storedValues = JSON.parse(json);
    return storedValues;
  }

  public get values() {
    try {
      const storedValues = this.getLocalStorage();
      this.schema.validateSync(storedValues);
      this._values = storedValues;
      return this._values;
    } catch (error) {
      this.values = this.defaults;
      return this.defaults;
    }
  }

  public set values(values) {
    this.setLocalStorage(values);
  }

  private setLocalStorage(values: any) {
    if (isBrowser()) {
      try {
        this.schema.validateSync(values);
        window.localStorage.setItem(this.key, JSON.stringify(values));
      } catch (error) {
        window.localStorage.setItem(this.key, JSON.stringify(this.defaults));
      }
    }
  }

  public reset() {
    this.values = this.defaults;
    return this.values;
  }

  public setValue(path: string, value: any) {
    const values = { ...this.values };
    _set(values, path, value);
    this.values = values;
    return this.values;
  }

  public getValue(path: string) {
    return _get(this.values, path);
  }
}
