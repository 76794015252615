import * as Sentry from "@sentry/browser";
import _isNil from "lodash.isnil";

const environment = process.env.GATSBY_ACTIVE_ENV;

Sentry.init({
  dsn: "https://f6a7f2f03b7a402384a9d7abed5dc26c@sentry.io/1890432",
  sampleRate: parseInt(process.env.GATSBY_ERROR_SAMPLE_RATE, 10),
  environment
});

export default Sentry;
