import _every from "lodash.every";
import { useState } from "react";
import { createContainer } from "unstated-next";
import * as yup from "yup";
import { PreferenceKeys, UnitCategory, UnitTypes } from "../../../../types/enums";
import PersistentStore from "./PersistentStore";
import { units as unitsByType } from "../../../../models/aeris/units";

const systemSchema = yup.string().required();
//.oneOf([UnitTypes.Imperial, UnitTypes.Metric, UnitTypes.Custom, 'kts'])
//.default(UnitTypes.Imperial);

const unitCategoryKey: { [key: string]: string } = {
  //temp
  "°C": UnitTypes.Metric,
  "°F": UnitTypes.Imperial,
  //speed
  "km/h": UnitTypes.Metric,
  mph: UnitTypes.Imperial,
  kts: UnitTypes.Custom,
  "m/s": UnitTypes.Custom,
  //distance
  km: UnitTypes.Metric,
  mi: UnitTypes.Imperial,
  //height
  m: UnitTypes.Metric,
  ft: UnitTypes.Imperial,
  //pressure
  mb: UnitTypes.Metric,
  in: UnitTypes.Imperial,
  //rain/snow
  mm: UnitTypes.Metric,
  //"in": UnitTypes.Imperial,
  cm: UnitTypes.Metric
};

interface TypesOfUnits {
  [key: string]: {
    imperial: string;
    metric: string;
    custom?: string;
  };
  temp: {
    imperial: string;
    metric: string;
  };
  speed: {
    imperial: string;
    metric: string;
    custom: string;
  };
  distance: {
    imperial: string;
    metric: string;
  };
  height: {
    imperial: string;
    metric: string;
  };
  pressure: {
    imperial: string;
    metric: string;
  };
  rain: {
    imperial: string;
    metric: string;
  };
  snow: {
    imperial: string;
    metric: string;
  };
}

const typesOfUnits: TypesOfUnits = {
  temp: {
    imperial: "°F",
    metric: "°C"
  },
  speed: {
    imperial: "mph",
    metric: "km/h",
    custom: "kts"
  },
  distance: {
    imperial: "mi",
    metric: "km"
  },
  height: {
    imperial: "ft",
    metric: "m"
  },
  pressure: {
    imperial: "in",
    metric: "mb"
  },
  rain: {
    imperial: "in",
    metric: "mm"
  },
  snow: {
    imperial: "in",
    metric: "cm"
  }
};

class UnitStore extends PersistentStore {
  key = PreferenceKeys.Units;

  defaults = {
    [UnitCategory.Temp]: unitsByType[UnitCategory.Temp][1],
    [UnitCategory.Speed]: unitsByType[UnitCategory.Speed][1],
    [UnitCategory.Distance]: unitsByType[UnitCategory.Distance][1],
    [UnitCategory.Height]: unitsByType[UnitCategory.Height][1],
    [UnitCategory.Pressure]: unitsByType[UnitCategory.Pressure][1],
    [UnitCategory.Rain]: unitsByType[UnitCategory.Rain][1],
    [UnitCategory.Snow]: unitsByType[UnitCategory.Snow][1]
  };

  schema = yup
    .object()
    .required()
    .shape({
      [UnitCategory.Temp]: systemSchema,
      [UnitCategory.Speed]: systemSchema,
      [UnitCategory.Distance]: systemSchema,
      [UnitCategory.Height]: systemSchema,
      [UnitCategory.Pressure]: systemSchema,
      [UnitCategory.Rain]: systemSchema,
      [UnitCategory.Snow]: systemSchema
    });

  get [UnitCategory.Temp]() {
    const value = this.getValue(UnitCategory.Temp);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Speed]() {
    const value = this.getValue(UnitCategory.Speed);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Distance]() {
    const value = this.getValue(UnitCategory.Distance);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Height]() {
    const value = this.getValue(UnitCategory.Height);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Pressure]() {
    const value = this.getValue(UnitCategory.Pressure);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Rain]() {
    const value = this.getValue(UnitCategory.Rain);
    return unitCategoryKey[value];
  }

  get [UnitCategory.Snow]() {
    const value = this.getValue(UnitCategory.Snow);
    return unitCategoryKey[value];
  }

  get type() {
    const type = unitCategoryKey[this.values[UnitCategory.Temp]];
    if (_every(Object.values(this.values), val => unitCategoryKey[val as string] === type)) {
      if (type === "imperial") {
        return UnitTypes.Imperial;
      } else if (type === "metric") {
        return UnitTypes.Metric;
      }
    }
    return UnitTypes.Custom;
  }

  set type(val: UnitTypes) {
    const values = this.values;
    Object.keys(values).forEach(key => {
      values[key] = typesOfUnits[key][val];
    });
    this.values = values;
  }
}

export const unitStore = new UnitStore();

export const useUnitStore = () => {
  const [units, setUnitState] = useState(unitStore.values);
  const [unitType, setUnitTypeState] = useState(unitStore.type);

  const update = () => {
    setUnitState({ ...unitStore.values });
    setUnitTypeState(unitStore.type);
  };

  const resetUnits = () => {
    unitStore.reset();
    update();
  };

  //controls the mph/kmh/kts buttons
  const setUnit = (path: UnitCategory, val: UnitTypes) => {
    unitStore.setValue(path, val);
    update();
  };

  //controls segment4d control of imperial|metric|custom
  const setUnitType = (val: UnitTypes) => {
    unitStore.type = val;
    update();
  };

  return { units, unitType, unitStore, resetUnits, setUnit, setUnitType };
};

export const UnitStoreContainer = createContainer(useUnitStore);
